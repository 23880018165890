<template>
  <a-alert type="warning" show-icon closable>
    <template #message>
      <div class="align-center">
        <span>完善及时认证信息，可以得到更多修理厂的关注哦！</span>
        <a-button type="link" @click="goAuthenticated">去认证</a-button>
      </div>
    </template>
  </a-alert>

  <div class="flex">
    <div class="flex-1 min-w-[890px]" style="min-width: 890px">
      <a-card title="询价动态" class="mycard">
        <a-list :data-source="inquiryData" :loading="inquiryLoading">
          <template #renderItem="{ item }">
            <a-list-item>
              <div class="w-full flex items-center justify-between">
                <div class="flex items-center">
                  <span class="mr-[10px]">{{ item.vehicleBrand }}</span>
                  <template v-if="item.containGoods.length > 30">
                    <a-tooltip :title="item.containGoods">
                      <span class="inline-block font-bold mr-[10px] max-w-[412px] truncate"
                        >{{ item.containGoods }}
                      </span></a-tooltip
                    >
                  </template>
                  <template v-else>
                    <span class="font-bold mr-[10px]">{{ item.containGoods }} </span>
                  </template>
                  <span>正在询价中，有</span>
                  <span>{{ item.quoteSupplierCount }}</span>
                  <span>家供应商提供了报价。</span>
                </div>
                <div>
                  <span class="text-gray-400">{{ item.lastCommitDatetime }}</span>
                </div>
              </div>
            </a-list-item>
          </template>
        </a-list>
      </a-card>

      <div class="py-10px"></div>

      <a-card title="待报价" class="mycard">
        <template #extra>
          <a-button type="link" @click="quoteQuotationaAll">全部</a-button>
        </template>
        <a-table
          :data-source="quoteData"
          :columns="quoteColumns"
          :loading="quoteLoading"
          rowKey="id"
          :pagination="false"
        >
          <template #branchName="{ text, record }">
            <a-tooltip :title="text">
              <span>{{ record.tenantShortName }}</span>
            </a-tooltip>
          </template>
          <template #operation="{ record }">
            <a class="text-primary operation-model" type="link" @click="quoteQuotationClick(record)">去报价</a>
          </template>
        </a-table>
      </a-card>

      <div class="py-10px"></div>

      <a-card title="待接单" class="mycard">
        <a-table
          :data-source="orderData"
          :columns="orderColumns"
          :loading="orderLoading"
          rowKey="id"
          :pagination="false"
        >
          <template #branchName="{ text, record }">
            <a-tooltip :title="text">
              <span>{{ record.inquiry.tenantShortName }}</span>
            </a-tooltip>
          </template>
          <template #preparedBy="{ record }">
            <span>{{ record.preparedBy }}</span>
            <a-tooltip :title="record.cellphone">
              <PhoneFilled
                class="cursor-pointer"
                style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px"
              />
            </a-tooltip>
          </template>
          <template #operation>
            <a-button class="operation-model" type="link" @click="goAuthenticated">去认证</a-button>
          </template>
        </a-table>
      </a-card>
    </div>
    <div class="ml-20px w-[380px]" style="width: 380px">
      <a-card title="平台数据" class="mycard">
        <div class="flex justify-between items-center">
          <div class="flex flex-col items-center">
            <span class="text-gray-400">采购商入驻数量</span>
            <span class="text-30px mt-10px">{{ 8000 }}</span>
          </div>
          <div class="w-1 h-40px bg-gray-100"></div>
          <div class="flex flex-col items-center">
            <span class="text-gray-400">平台询价次数</span>
            <span class="text-30px mt-10px">{{ 8000 }}</span>
          </div>
        </div>
      </a-card>

      <div class="mt-20px">
        <a-card title="经营品牌" class="mycard">
          <!-- <template #extra>
            <a-button type="link">全部</a-button>
          </template> -->
          <div class="flex flex-wrap">
            <span class="px-8px py-5px mr-10px mb-12px" v-for="(brand, index) of supplier.brands" :key="index">{{
              brand
            }}</span>
            <span
              class="h-32px px-8px py-5px border border-primary cursor-pointer color-primary"
              style="color: #0d8d8d"
              @click="addBrands"
              >+ 添加</span
            >
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import {
  Alert as AAlert,
  Card as ACard,
  List as AList,
  ListItem as AListItem,
  Tooltip as ATooltip,
  message
} from 'ant-design-vue'
import { PhoneFilled } from '@ant-design/icons-vue'
import moment from 'moment'

import { useAjax, useTable2, formatDate, formatMoney, useStore } from '@vue-mfe/utils'
import { useRouter } from 'vue-router'

interface InquiryState {
  branchName: string
  vehicleBrand: string
  containGoods: string
  quoteSupplierCount: number
  commitDatetime: string
  lastCommitDatetime: string
}

interface QuoteState {
  id: number
  vin: string
  vehicleSpec: string
  branchName: string
  commitBy: string
  inqueryTime: string
}

interface OrderState {
  id: number
  vehicleSpec: string
  branchName: string
  preparedBy: string
  amount: number
  commitDatetime: string
}
interface SupplierState {
  vehicleBrands: string[]
  customKeywords: any[]
  parts: any[]
  businessType: {
    code: string
  }
}

const router = useRouter()
const store = useStore()
const goAuthenticated = () => {
  router.push(
    {
      path: '/supplier/setting',
      query: { tab: 'basic', finished: 'true' }
    }
  )
}
const { data: inquiryData, loading: inquiryLoading } = useAjax<InquiryState>(
  {
    action: 'GET /supplier/quotation/visitor/inquiry',
    params: { pageSize: 5 },
    convert: {
      client (data) {
        return data.map(
          (item: any) => {
            if (moment(moment()).diff(moment(item.commitDatetime), 'days') < 3) {
              item.lastCommitDatetime = moment(item.commitDatetime).fromNow()
            } else {
              item.lastCommitDatetime = formatDate(item.commitDatetime)
            }
            return item
          }
        )
      }
    }
  }
)

const {
  data: quoteData,
  loading: quoteLoading,
  columns: quoteColumns
} = useTable2<QuoteState>(
  {
    columns: [
      // { title: '序号', customRender: ({ index }) => index + 1 },
      { title: 'VIN码', dataIndex: 'vin' },
      {
        title: '车型',
        width: 220,
        ellipsis: true,
        customRender: ({ record }) =>
          record.vin
            ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                record.vehicleSystem
              } ${record.vehicleYear} ${record.vehicleDisplacement}`
            : record.vehicleBrand
      },
      { title: '采购商', dataIndex: 'branchName', slots: { customRender: 'branchName' } },
      { title: '询价人', dataIndex: 'commitBy' },
      { title: '询价时间', customRender: ({ record }) => formatDate(record.commitDatetime) },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    ajax: {
      get: {
        action: 'GET /supplier/quotation/inquiry',
        params: { pageSize: 5, quoteStatus: 'P' }
      }
    }
  }
)
const quoteQuotationClick = (record: QuoteState) => {
  router.push({ name: 'supplier-supply-quote-_id', params: { id: record.id } })
}
const quoteQuotationaAll = () => {
  router.push({ name: 'supplier-supply-quote' })
}
const {
  data: orderData,
  loading: orderLoading,
  columns: orderColumns
} = useTable2<OrderState>(
  {
    columns: [
      // { title: '序号', customRender: ({ index }) => index + 1 },
      {
        title: '车型',
        width: 220,
        ellipsis: true,
        customRender: ({ record }) =>
          record.inquiry.vin
            ? `${record.inquiry.vehicleBrand}${
                record.inquiry.vehicleSubBrand ? ' ' + record.inquiry.vehicleSubBrand : ''
              } ${record.inquiry.vehicleSystem} ${record.inquiry.vehicleYear} ${record.inquiry.vehicleDisplacement}`
            : record.inquiry.vehicleBrand
      },
      { title: '采购商', dataIndex: 'inquiry.branchName', slots: { customRender: 'branchName' } },
      { title: '下单人', slots: { customRender: 'preparedBy' } },
      { title: '总价', dataIndex: 'amount', customRender: ({ text }) => formatMoney(text, '￥') },
      { title: '下单时间', dataIndex: 'preparedDatetime', customRender: ({ text }) => formatDate(text) },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /supplier/purchase',
        params: { pageSize: 5, status: 'P' }
      }
    }
  }
)
let supplier = reactive(
  {
    brands: [] as string[]
  }
)
useAjax<SupplierState>(
  {
    action: 'GET /supplier/security/currentsupplier',
    success (data) {
      if (data[0].businessType.code === 'W') {
        supplier.brands = data[0].vehicleBrands
      } else {
        supplier.brands = data[0].parts
          .map((inner: any) => inner.message)
          .concat(data[0].customKeywords.map((inner: any) => inner.keyword))
      }
    }
  }
)
const addBrands = () => {
  if (store.state.user.role && store.state.user.role.code === 'M') {
    router.push({ path: '/supplier/setting', query: { tab: 'manage' } })
  } else {
    message.warning(
      {
        title: '提示',
        content: '您无权设置此项!'
      }
    )
  }
}
</script>
<style scoped>
.mycard :deep(.ant-card-head-title) {
  font-weight: bold;
}
</style>
